<template>
  <div class="merchant-add">
    <GlobalChunk :icon="DetailIMG" :title="titleDetail" :padding="[32, 0, 95, 55]">
      <el-row>
        <el-col :span="12">
          <GlobalForm
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="提交"
          >
             <el-form-item class="el_from" label="省市区:" slot="operation" :required="true">
              <Provinces
                @areaID="areaChild"
                @provinceID="provinceChild"
                @cityID="cityChild"
                :addressInfo="addressInfo"
                :optionsProvince="optionsProvince" 
                :optionsArea="optionsArea"
                :optionsCity="optionsCity"
              />
            </el-form-item>
            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { formValidateNumber } from "@/utils/form-validate";
import Provinces from "@/components/plugins/provinces";
export default {
  name: "merchant-add",
  components: { Provinces },
  data() {
    return {
      titleDetail:"",
      DetailIMG:"",
      MerchantFormData:[],
      optionsProvince: [],
      optionsCity: [],
      optionsArea: [],
      addressInfo:{
        provinceID:"",
        cityID:"",
        areaID:"",
      },
      initData: {
        merchantId:"",
        merchantName: "",
        contactName: "",
        contactPhone: "",
        province: "",
        merchantAddress: "",
        entryTime: "",
        establishTimeNum: "",
        merchantSimpleIntro:"",
        isPayDeposit: false,
        businessLicense: "",
        merchantLogo: ""
      },
      formItemList: [
        {
          key: "merchantName",
          type: "input",
          labelName: "商家名称",
          placeholder: "输入商家名称（10字内）"
        },
        { slotName: "operation" },
        // {
        //   key: "province",
        //   slotName: "provinces",
        //   labelName: "省 市 区"
        // },
        {
          key: "merchantAddress",
          type: "input",
          labelName: "商家地址",
          placeholder: "输入商家详细地址（限50字内）"
        },
        {
          key: "establishTimeNum",
          type: "input",
          labelName: "成立时间",
          placeholder: "输入商家成立的时间年限（50以内的正整数）"
        },
        {
          key: "merchantSimpleIntro",
          type: "textarea",
          labelName: "商家简介",
          placeholder: "输入商家简介（100字内）"
        },
        {
          key: "merchantLogo",
          type: "upload",
          labelName: "商家logo",
          placeholder: "最多支持上传1张图片，大小不超过5M，格式为JPG或PNG，建议尺寸100*100px"
        }
      ],
      formRules: {
        // province:[
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请选中省市区",
        //   }
        // ],
        merchantName: [
          {
            required: true,
            trigger: "blur",
            message: "输入商家名称（10字内）",
            max: 10,
          }
        ],
        merchantAddress: [
          {
            required: true,
            trigger: "blur",
            message: "输入商家详细地址（限50字内）",
            max: 50,
          }
        ],
        establishTimeNum: [
          {
            required: true,
            trigger: "blur",
            validator:formValidateNumber,
          }
        ],
        merchantSimpleIntro: [
          {
            required: true,
            trigger: "blur",
            message: "输入商家简介（100字内）",
            max: 100,
          }
        ],
        merchantLogo: [
          {
            required: true,
            trigger: "blur",
            message: "请上传商家Logo",
          }
        ],
      }
    };
  },
  mounted() {
    if(this.$route.query.type ==='edit'){
      this.titleDetail = '编辑商家'
      this.DetailIMG = 'edit'
      _api.ShopTableXQ(this.$route.query.id).then(res =>{
          if(res.code === 1){
            this.MerchantFormData = res.data
            this.initData.merchantName = this.MerchantFormData.merchantName;
            this.initData.merchantAddress = this.MerchantFormData.merchantAddress;
            this.initData.establishTimeNum = this.MerchantFormData.establishTimeNum;
            this.initData.merchantSimpleIntro = this.MerchantFormData.merchantSimpleIntro;
            this.initData.merchantLogo = this.MerchantFormData.merchantLogo;
            this.initData.merchantId=this.MerchantFormData.merchantId
            this.addressInfo.provinceID=this.MerchantFormData.provinceId
            this.addressInfo.cityID=this.MerchantFormData.cityId
            this.addressInfo.areaID=this.MerchantFormData.regionId
            this.provincesMount()
          }
        })
    }else{
      this.titleDetail = '添加商家'
      this.DetailIMG = 'add'
      _api.listAudit(0).then(res => {
      if (res.code === 1) {
        this.optionsProvince = res.data;
        }
      });
    }
  },
  methods: {
    provincesMount(){
      _api.listAudit(0).then(res => {
      if (res.code === 1) {
        this.optionsProvince = res.data;
        }
      });
      _api.listAudit(this.MerchantFormData.provinceId).then(res => {
        this.optionsCity = res.data;
        console.log(this.optionsCity)
      });
      _api.listAudit(this.MerchantFormData.cityId).then(res => {
        this.optionsArea = res.data;
        console.log(this.optionsArea)
      });
    },
    areaChild(val) {
        this.addressInfo.areaID = val
    },
    provinceChild(val) {
        this.addressInfo.provinceID = val
        this.addressInfo.cityID = ""
      this.addressInfo.areaID=""
      _api.listAudit(val).then(res => {
        if (res.code === 1) {
          this.optionsCity = res.data;
        }
      });
    },
    cityChild(val) {
        this.addressInfo.cityID = val
        _api.listAudit(val).then(res => {
        if (res.code === 1) {
          this.optionsArea = res.data;
        }
      });
    },
    handleConfirm(data, cd) {
      cd();
      console.log(data.merchantId)

      console.log("提交了form", data);
      const vo = {
        provinceId:this.addressInfo.provinceID,
        cityId:this.addressInfo.cityID,
        regionId:this.addressInfo.areaID,
        merchantId:this.initData.merchantId,
        merchantName: data.merchantName,
        merchantAddress: data.merchantAddress,
        establishTimeNum: data.establishTimeNum,
        merchantSimpleIntro: data.merchantSimpleIntro,
        merchantLogo: data.merchantLogo,
        merchantType:"01",
      };
      if(data.establishTimeNum>50||!data.establishTimeNum){
        this.$message.error('请输入正确成立年限(50以内的正整数)')
      }else if(!this.addressInfo.areaID){
        this.$message.error('请选择省市区')
      }
      else{
        if (this.$route.query.type ==='edit') {
        _api.ShopTableEdit(vo).then(res => {
          if (res.code === 1) {
            this.$router.push({ path: "/SystemOffer/list" });
            this.$message({
              message: "修改成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error"
            });
          }
        });
        } else {
          _api.ShopTableAdd(vo).then(res => {
            if (res.code === 1) {
              this.$router.push({ path: "/SystemOffer/list" });
              this.$message({
                message: "添加成功",
                type: "success"
              });
            } else {
              this.$message({
                message: "添加失败",
                type: "error"
              });
            }
          });
        }
      }
      
    },
    FromBack(){
      this.$router.go(-1)
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-add {
  .el_from {
    margin-left: -120px;
  }
}
</style>
